import React from "react";
import Footer from "./footer";

export const PrintComponent = React.forwardRef((props, ref) => {
  const { printDate, printUser, payment } = props;
  return (
    <div ref={ref}>
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-left padding-right-25">
                <a href="#">
                  <img
                    class="media-object img-circle templatemo-img-bordered"
                    src="assets/images/person.jpg"
                    alt="Sunset"
                  />
                </a>
              </div>
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {printUser.firm}
                </h2>
                <p>printed by:{printUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Rent Receipt</td>
                    <td>Printed on {printDate}</td>
                  </tr>
                  <div style={{ padding: 100 }} />
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>Payers Information</>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Name</td>
                    <td> {payment.tenantName}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>Amount</>
                    </td>
                    <td> {payment.payment}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>Start</>
                    </td>
                    <td> {payment.startDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>End</>
                    </td>
                    <td> {payment.endDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>Durations</>
                    </td>
                    <td> {payment.durations}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <>Recorded on</>
                    </td>
                    <td> {payment.recordDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>{" "}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
});
