import { defaultUrlEndpoint, fetcher } from "../components/http"
import useSWR from 'swr';
//import useSWRImmutable from 'swr/immutable';

export function useDefaults() {
  const { data, error, isLoading } = useSWR(
    'https://cloud.rentandflex.com/api/default/',
    fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
  );

  return {
    default: data,
    dloading:isLoading,
    derror:error,
  };
}

export function useDefault(firm) {
    let url = defaultUrlEndpoint + firm;
    const { data, error, isLoading } = useSWR(
      url,
      fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
    );
  
    return {
      default: data,
      dloading:isLoading,
      derror:error,
    };
  }