import React, { useContext } from "react";

import { AuthContext } from "../context/authContext";
import AllMarketList from "../list/AllMarketList";
import SidebarAdmin from "../components/sidebarAdmin";
import NavAdmin from "../components/navAdmin";

function AllMarket() {
  const { currentUser } = useContext(AuthContext);

  /* Search input
  const [inputs, setInputs] = useState([]);
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

*/
  return (
    <div>
      <div class="templatemo-flex-row">
        <SidebarAdmin />
        <div class="templatemo-content col-1 light-gray-bg">
          <NavAdmin />
          <div class="templatemo-content-container">
            <div class="templatemo-flex-row flex-content-row">
              <div class="templatemo-flex-row flex-content-row">
                <div class="templatemo-content-widget white-bg col-2">
                  <i class="fa fa-times"></i>
                  <div class="media margin-bottom-30">
                    <div class="media-left padding-right-25">
                      <a href="#">
                        <img
                          class="media-object img-circle templatemo-img-bordered"
                          src="assets/images/person.jpg"
                          alt="Sunset"
                        />
                      </a>
                    </div>
                    <div class="media-body">
                      <h2 class="media-heading text-uppercase blue-text">
                        {currentUser.username}
                      </h2>
                      <p>{currentUser.email}</p>
                    </div>
                  </div>
                </div>

                <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
                  <img
                    src="assets/images/sunset-big.jpg"
                    alt="Sunset"
                    class="img-responsive content-bg-img"
                  />
                  <i class="fa fa-heart"></i>
                  <h2 class="templatemo-position-relative white-text">
                    Sunset
                  </h2>
                  <div class="view-img-btn-wrap">
                    <a href="" class="btn btn-default templatemo-view-img-btn">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* The table */}
            <AllMarketList />
            {/* Pagination*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllMarket;
