import React, { useRef } from "react";
import $ from "jquery";
import { findDOMNode } from "react-dom";
import { Link } from "react-router-dom";
export default function SidebarAdmin() {
   
    const componentRef = useRef();
    const handleToggle = () => {
      const el = findDOMNode(componentRef.current);
      $(el).slideToggle();
    };
  return (
<div className="templatemo-sidebar">
      <header className="templatemo-site-header">
        <div className="square"></div>
        <h1>Rent And Flex</h1>
        <p style={{color:"greenyellow"}}>Excellent Property Managers</p>
      </header>
      <div className="profile-photo-container">
        
        <div className="profile-photo-overlay"></div>
      </div>
      <form className="templatemo-search-form" role="search">
        <div className="input-group">
          <button type="submit" className="fa fa-search"></button>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            name="srch-term"
            id="srch-term"
          />
        </div>
      </form>
      <div className="mobile-menu-icon" onClick={(e) => handleToggle(e)}>
        <i className="fa fa-bars"></i>
      </div>
      <nav className="templatemo-left-nav" ref={componentRef}>
        <ul>
         
          <li>
            <Link to="/">
              <i className="fa fa-home fa-fw"></i>Accounts
            </Link>
          </li>
          
            <li>
              <Link to="/allListing/">
                <i className="fa fa-map-marker fa-fw"></i>MarketPlaces
              </Link>
            </li>
                  
          
          
            <li>
              <Link to="/listing/">
                <i className="fa fa-map-marker fa-fw"></i>Listings
              </Link>
            </li>
         
         
          <li>
            <Link to="/payment/">
              <i className="fa fa-database fa-fw"></i>Payments
            </Link>
          </li>
          
            <li>
              <Link to="/users/">
                <i className="fa fa-users fa-fw"></i>Users
              </Link>
            </li>
          
        </ul>
      </nav>
    </div>
  )
}
