import { createContext, useState, useEffect, useContext } from "react";
import http from "../components/http";

export const AuthContext = createContext();

export const useAuth =()=> useContext(AuthContext)

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const login = async (inputs) => {
   try{const res = await http.post("/auth/login", inputs);
    console.log(res);
    setCurrentUser(res.data.user);} 
   // 
   catch(err){
    console.log(err)
   }

  };

  const logOut = async () => {
    await http.post("/auth/logout");
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};
