import Describe from "../components/describe";
import Footer from "../components/footer";



import MarketProfile from "../components/marketProfile";
import Card from "../components/card";
import Latestpayment from "../components/latestPayment";
import SidebarAdmin from "../components/sidebarAdmin";
import NavAdmin from "../components/navAdmin";
import CardAdmin from "../components/cardAdmin";
import Chart from "../components/chart";

function Admin() {
  return (
    <>
      <div className="templatemo-flex-row">
        <SidebarAdmin />
        <div className="templatemo-content col-1 light-gray-bg">
          <NavAdmin />
          <div className="templatemo-content-container">
            {/*Banner Column
            {market && market.map((m) => console.log(m))}*/}
            <CardAdmin/>
            <Latestpayment/>
<Chart/>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
