import Footer from "../components/footer";
import { React, useState, useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";

function Login(props) {
//Toggle Password
const [isOpen, setIsOpen] = useState(false);
const toggleOpen = () => {
  setIsOpen((open) => !open);
};
  const [password, passUser] = useState("");
  const [username, emailUser] = useState("");
  const [error, setError] = useState(false);
  const { login } = useContext(AuthContext);
  //Login
  const loginHandler = async (e) => {
    e.preventDefault();
    setError(false);
    const inputs = {
      username,
      password,
    };
    try {
      await login(inputs);

      window.location.replace("/");
    } catch (err) {
      toast.error(err.response.data)
      setError(true);    }
  };

  return (
    <>
      <div className="templatemo-content col-1 light-gray-bg">
        <div className="templatemo-content-widget templatemo-login-widget white-bg">
          <header className="text-center">
            <div className="square"></div>
            <h1>Rent And Flex</h1>
            <p>Excellent Property Managers</p>
          </header>
          {error && (
            <span style={{ color: "red" }}> Login is unsuccessful</span>
          )}

          <form
            method="post"
            onSubmit={loginHandler}
            className="templatemo-login-form"
          >
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="fa fa-user fa-fw"></i>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Username"
                  required={true}
                  onChange={(e) => emailUser(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
                
                <input
                  type={isOpen?"text":"password"}
                  className="form-control"
                  id="password"
                  placeholder="Your Password"
                  required={true}
                  onChange={(e) => passUser(e.target.value)}
                /><div className="input-group-addon" >
                  
                  <i className={isOpen?"fa fa-key fa-fw":"fa fa-eye"}onClick={toggleOpen}></i>
                </div>
              </div>
            <div className="form-group">
              <div className="checkbox squaredTwo">
                <input type="checkbox" id="c1" name="cc" />
                <label htmlFor="c1">
                  <span></span>Remember me
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="templatemo-blue-button width-100"
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Not a registered user yet?{" "}
            <strong>
              <a href="https://home.rentandflex.com/sign-up" className="blue-text">
                Sign up now!
              </a>
            </strong>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Login;
