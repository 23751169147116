import React, {useContext} from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import  { propertyUrlEndpoint } from "../components/http.jsx";
import { AuthContext } from "../context/authContext.js";
import { Link } from "react-router-dom";
import useSWR from "swr";


const fetcher = (...args) => fetch(...args).then((response) => response.json());

function Payment() {
  const { currentUser } = useContext(AuthContext);
  let endPoint = propertyUrlEndpoint + currentUser.firm;
  const { isLoading, data: property, error } = useSWR(endPoint, fetcher);
  let num = 1;
  if (isLoading) return <p>{"Properties are Loading"}</p>;
  if (error) return <p>{error.message}</p>;

  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-widget no-padding">
        <div class="panel panel-default table-responsive">
          <table class="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    # <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Property <span class="caret"></span>
                  </a>
                </td>

                
                <td>View Expired Rents</td>
              </tr>
            </thead>
            {property
              ? property.map((property, key) => {
                  return (
                    <tbody>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>
                      <Link
                        to={`/payment/${property.slug}`}
                        state={property.id}
                        className="templatemo-link"
                      >
                        {property.title}
                      </Link>
                    </td>

                        <td>
                          <Link
                            to={`/payment/${property.slug}`}
                            state={property.id}
                            className="templatemo-link"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : isLoading && <p>Loading...</p>}
          </table>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
