import React from "react";
import {
  imgUrl,

} from "../components/http";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import { useMarket } from "../data/market";

function MarketProfile(props) {

    const { currentUser } = useContext(AuthContext);
console.log(currentUser)
   const {mkLoading, market, mkerror } = useMarket(currentUser.firm)
if (mkLoading) return <p>{"Data is fetching"}</p>;

 
  //let expireRents = defaultHandler(payment, currentDate);
  if (mkerror) return <p>{"Failed to fetch"}</p>;

  return (
    <div className="templatemo-flex-row flex-content-row">
      <div className="templatemo-content-widget white-bg col-2">
        <i className="fa fa-times"></i>
        <div className="square"></div>
        <h2 className="text-uppercase">{market && market.title}</h2>

        <hr />
        <p>{market && market.description} </p>
      </div>
      <div className="templatemo-content-widget white-bg col-1 text-center">
        <i className="fa fa-times"></i>
        <h2 className="templatemo-inline-block">Public Photo</h2>
        <h3 className="text-lowercase margin-bottom-10"></h3>
        <img
          src={market && imgUrl + market.photo}
          alt="Bicycle"
          className="img-circle img-thumbnail"
        />
      </div>
      <div className="templatemo-content-widget white-bg col-1">
        <i className="fa fa-times"></i>
        <h2 className="text-uppercase">Get Started</h2>
      
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>1</div>
                </td>
                <td>
                  <Link to={"/property"}>Update Public Info</Link>
                </td>
              </tr>
              <tr>
                <td>
                  <div>2</div>
                </td>
                <td>
                  <Link to={"/property"}>Set Up Accounts</Link>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <Link to={"/property"}></Link>
                </td>
              </tr>
            
            </tbody>
          </table>
        </div>

        
      </div>
    </div>
  );
}

export default MarketProfile;
