import React from "react";

function Pagination({ recordsPerPage, order, paginate }) {
  /* const pageNumber = [];

  for (let i = 1; 1 <= Math.ceil(list / recordsPerPage); i++) {
    pageNumber.push(i);
  } */
  const npage = Math.ceil(order / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center mb-0">
        {numbers.map((n) => {
          return (
            <li className="page-item active" aria-current="page" key={n}>
              <button className="page-link" onClick={() => paginate(n)}>
                {n}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Pagination;
