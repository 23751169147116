import React, {useContext} from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import DefaultList from "../list/DefaultList";
import { useLocation } from "react-router-dom";
import PaymentList from "../list/PaymentList";




function SinglePayment() {
    const property = useLocation().state;
console.log(property)
  

  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          
          <PaymentList id={property}/>
        </div>
      </div>
    </div>
  );
}

export default SinglePayment;
