import React,{useContext, useState} from 'react'
import { AuthContext } from '../context/authContext';
import useSWR from 'swr';
import { marketUrlEndpoint, paymentUrlEndpoint } from './http';
import moment from 'moment';
import { Link } from 'react-router-dom';

const fetcher = (...args) => fetch(...args).then((response) => response.json());

export default function Latestpayment() {
  let num = 1;
  const { currentUser } = useContext(AuthContext);
  const [number, setNumber] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  let endPoint = paymentUrlEndpoint + currentUser.firm;
  const { isLoading, data: payment, error } = useSWR(endPoint, fetcher);
  let endPoint2 = marketUrlEndpoint
  const { isLoading:m, data: market, error:e } = useSWR(endPoint2, fetcher);
  if (isLoading) return <p>{"Payment is Loading"}</p>;
  if (m) return <p>{"Payment is Loading"}</p>;
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const orders = payment.slice(firstIndex, lastIndex);

 

  // const result = Object.groupBy(payment, ({ tenantName }) => tenantName);
  // const res=  Object.values(result);
 //  const s = Object.entries(result);
  // if(s.length ===1) console.log(s)
//console.log(s)


//months.forEach((m)=>{})
  
//console.log(page)


  return (
    <div class="templatemo-content-widget no-padding">
<p>
          To Receive Payment Notices, {""}
          <strong>
            <Link to="/write" class="blue-text">
              Set Up Accounts here in few steps
            </Link>
          </strong>
        </p>
        
    <div class="panel panel-default table-responsive">
      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Paid On <span class="caret"></span>
              </a>
            </td>

            <td>
              <a href="" class="white-text templatemo-sort-by">
                Tenant <span class="caret"></span>
              </a>
            </td>

            <td>
              <a href="" class="white-text templatemo-sort-by">
                Payment <span class="caret"></span>
              </a>
            </td>
            <td>Duration</td>
            <td>Expiring on</td>
            
          </tr>
        </thead>
        {orders
          ? orders.map((payment, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>{payment.paidDate.slice(0,10)}</td>
                    <td>{payment.tenantName}</td>
                    <td>{payment.payment}</td>
                    <td>{payment.durations}</td>
                    
                    <td>{moment(payment.endDate).fromNow()}</td>
                    
                  </tr>
                </tbody>
              );
            })
          : isLoading && <p>Loading...</p>}
      </table>
    </div>
  </div>
  )
}
