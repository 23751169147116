import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { PrintComponent } from "../components/printComponent";

const PrintPage = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <PrintComponent ref={componentRef} />
      <button onClick={handlePrint}>Print this out!</button>
    </div>
  );
};
export default PrintPage;
