import React from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import UserList from "../list/UserList";

function Users(props) {
  return (
    <>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <div class="templatemo-flex-row flex-content-row">
              <UserList />
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
