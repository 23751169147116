import React from 'react'
import useSWR from "swr";
import {
  marketUrlEndpoint,
  paymentUrlEndpoint,
  propertyUrlEndpoint,
  defaultHandler,
} from "../components/http";

import { Link } from "react-router-dom";

export default function CardAdmin() {
    const currentDate = new Date();

  const fetcher = (...args) =>
    fetch(...args).then((response) => response.json());
  const endPoint1 = propertyUrlEndpoint 
  const endPoint2 = paymentUrlEndpoint
  const endPoint3 = marketUrlEndpoint

  const {
    isLoading: isptyLoading,
    data: property,
    error,
  } = useSWR(endPoint1, fetcher);
  const {
    isLoading: ispayLoading,
    data: payment,
    error: err,
  } = useSWR(endPoint2, fetcher);
  
  const {
    isLoading: isMarketLoading,
    data: market,
    error: errs,
  } = useSWR(endPoint3, fetcher);
  

  if (ispayLoading) return <p>{"data is loading"}</p>;
  if (isptyLoading) return <p>{"data is loading"}</p>;
  if (isMarketLoading) return <p>{"data is loading"}</p>;

  //console.log(property,payment,market)
  let summary1 = property && property.length;
  let summary2 = payment && payment.length 
  let summary3 = market && market.length 

//  if (payment) defaultHandler(payment, currentDate);
  //let expireRents = defaultHandler;
  const options = [
    { label: "Total Firms", value: summary3, style:"pink", link:'allListing', error: errs },

    { label: "Total Properties", value: summary1, style:"green", link:'listing', error: error },
    { label: "Total Payments", value: summary2, style:"blue", link:'payment', error: err },

    // { label: "Expired Rents", value: expireRents.length, style:"pink", link:'defaults' },
    { label: "Vacancies", value: "000",  style:"yellow" },
  ];
  return (
    
<div className="templatemo-flex-row flex-content-row">
{options.map((option) => (<>
    <div key={option.value} className="templatemo-content-widget white-bg col-2">
        <i className="fa fa-times"></i>
        <div className={`circle ${option.style}-bg`}></div>
        <Link to={`/${option.link}`}>

      <h2 className="templatemo-inline-block">{option.label}</h2>
</Link>
        
        <div className="table-responsive">
          <table className="table">
            <tbody>
                            
              <tr>
              {option.error?"Failed to fetch ":  <td>{option.value}</td>}

                
               
              </tr>
            </tbody>
          </table>
        </div>

    
        <p>{"0 % up this month"} </p>
      </div>
                  </>))}
    
   
      
    </div>  )
}

