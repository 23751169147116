import React, { useState } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";

const SelectField = (props) => {
  const [value, setvalue] = useState([]);

  const handleOnchange = (val) => {
    setvalue(val);
    props.onSelect(value);
  };

  const options = [
    { label: "Art Gallery", value: "Art Gallery" },
    { label: "Bars and Nightclub", value: "Bars and Nightclub" },
    { label: "Community Centre", value: "Community Centre" },
    { label: "Conference Center", value: "Conference Centre" },
    { label: "Event Halls", value: "Event Halls" },
    { label: "Hotel", value: "Hotel" },
    { label: "Pool side", value: "Pool Side" },
    { label: "Resorts", value: "Resorts" },
    { label: "Stadium/Arenas", value: "Stadium/Arenas" },
    { label: "Tents", value: "Tents" },
  ];

  return (
    <div className="col-lg-12">
      <div className="preview-values">
        <span>Select Property Type(s)</span>
      </div>

      <MultiSelect onChange={handleOnchange} options={options} />
    </div>
  );
};
export default SelectField;
