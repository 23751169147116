import React, { useState, useEffect, useContext } from "react";
import Nav from "../components/nav";
import http from "../components/http";
import { Link } from "react-router-dom";
import Sidebar from "../components/sideBar";
import Details from "../components/details";
import { AuthContext } from "../context/authContext";
import { useLocation } from "react-router-dom";

function Tenant() {
  const location = useLocation();
  const property = location.pathname.split("/")[2];
  console.log(property);
  const { currentUser } = useContext(AuthContext);

  let num = 1;
  let firm = currentUser.firm;
  // Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);
  const npage = Math.ceil(posts.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  console.log(records);
  useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`tenant/${property}`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);

  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <Details
              th1={"Total Properties"}
              th2={"Total Cities"}
              th3={"Total States"}
              th4={"Vacant Properties"}
              total={posts.length}
            />

            {/* The table */}
            <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
              <p>
                We are Growing,{" "}
                <strong>
                  <Link to="/addTenant" class="blue-text">
                    List the new Tenant here
                  </Link>
                </strong>
              </p>
            </div>
            {/* The search */}
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <table class="table table-striped table-bordered templatemo-user-table">
                  <thead>
                    <tr>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          # <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Name <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Phone <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Rent <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Apartment <span class="caret"></span>
                        </a>
                      </td>
                      <td>Edit</td>
                      <td>View</td>
                      <td> Tenants</td>
                      <td>Delete</td>
                    </tr>
                  </thead>
                  {records &&
                    records.map((m, key) => {
                      return (
                        <tbody>
                          <tr key={key}>
                            <td>{num++}</td>
                            <td>{m.title}</td>
                            <td>{m.phone}</td>
                            <td>{m.rent}</td>
                            <td>{m.description}</td>
                            <td>
                              <Link
                                to={`/addTenant/`}
                                state={m}
                                class="templatemo-edit-btn"
                              >
                                {" "}
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/blog/${m.slug}`}
                                state={m}
                                className="templatemo-link"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/payment/${m.slug}`}
                                state={m}
                                className="templatemo-edit-btn"
                              >
                                Payment
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/addRent/`}
                                state={m}
                                className="templatemo-link"
                              >
                                Add Payments
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                {/* Pagination */}
                <div className="col-lg-12 col-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center mb-0">
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Previous"
                          onClick={prePage}
                        >
                          <span aria-hidden="true">Prev</span>
                        </Link>
                      </li>
                      {numbers.map((n, i) => {
                        <li
                          className="page-item active"
                          aria-current="page"
                          key={i}
                        >
                          console.log(n)
                          <Link
                            className="page-link"
                            to="/market"
                            onClick={changePage}
                          >
                            <span aria-hidden="true">{n}</span>
                          </Link>
                        </li>;
                      })}

                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Next"
                          onClick={nextPage}
                        >
                          <span aria-hidden="true">Next</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tenant;
