import Footer from "../components/footer";
import Nav from "../components/nav";

import { Link, useLocation } from "react-router-dom";
import PaymentList from "../list/PaymentList";
import TenantList from "../list/TenantList";

function Single() {
  // To parse data

  const state = useLocation().state;
  return (
    <>
      <div class="templatemo-flex-row">
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <div class="templatemo-flex-row flex-content-row">
              <div class="templatemo-flex-row flex-content-row">
                <div class="templatemo-content-widget white-bg col-2">
                  <i class="fa fa-times"></i>
                  <div class="media margin-bottom-30">
                    <div class="media-body">
                      <h2 class="media-heading text-uppercase blue-text">
                        {state.title}{" "}
                      </h2>
                      <p>{state.slug}</p>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>
                            <div class="circle green-bg"></div>
                          </td>
                          <td>State</td>
                          <td>{`${state.city} , ${state.state}`}</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="circle pink-bg"></div>
                          </td>
                          <td>LandLord</td>
                          <td>{state.landLord}</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="circle blue-bg"></div>
                          </td>
                          <td>Phone</td>
                          <td>{state.phone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <TenantList id={state.id} />
            <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
              <p>
                We are Growing,{" "}
                <strong>
                  <Link to={`/addRent/${state.id}`} class="blue-text">
                    List the new Payment here
                  </Link>
                </strong>
              </p>
            </div>
            <PaymentList id={state.id}/>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Single;
