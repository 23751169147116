import React, { useContext } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import moment from "moment";

import { AuthContext } from "../context/authContext.js";

import { useDefault } from "../data/default.js";




const fetcher = (...args) => fetch(...args).then((response) => response.json());

function DefaultList({id}) {
  const { currentUser } = useContext(AuthContext);
  const { dloading, default:payment, derror } = useDefault(currentUser.firm);
  let num = 1;
  if (dloading) return <p>{"Payments are Loading"}</p>;
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };*/
  //const filter = payment.filter((f) => f.propertyId.includes(props.id));
  //console.log(filter);
 // console.log(payment)
 // const currentDate = new Date();

 // const expiredRents = defaultHandler(payment, currentDate);
  const filter = (id) => {
    return payment.filter((f) => f.propertyId ==id);
  };
  const list = filter(id)

  if (derror) return <p>{derror.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-left padding-right-25">
                <a href="#">
                  <img
                    class="media-object img-circle templatemo-img-bordered"
                    src="assets/images/person.jpg"
                    alt="Sunset"
                  />
                </a>
              </div>
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>
                      <Link to={"/payment"}>Total Payments</Link>
                    </td>

                    <td>{payment && payment.length}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Expired Rents</td>
                    <td>{list && list.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* The table */}

      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      <div class="templatemo-content-widget no-padding">
        <div class="panel panel-default table-responsive">
          <table class="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    # <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Paid On <span class="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Tenant <span class="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Payment <span class="caret"></span>
                  </a>
                </td>
                <td>Duration</td>
                <td>Expiring on</td>

                <td>View</td>
              </tr>
            </thead>
            {list
              ? list.map((payment, key) => {
                  return (
                    <tbody key={key}>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>{payment.paidDate}</td>

                        <td>{payment.tenantName}</td>
                        <td>{payment.payment}</td>
                        <td>{payment.durations}</td>
                        <td style={{ color: "red" }}>{moment(payment.endDate).fromNow()}</td>
                        <td>
                          <Link
                            to={`/property/${payment.slug}`}
                            className="templatemo-link"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : dloading && <p>Loading...</p>}
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
        <img
          src="assets/images/sunset-big.jpg"
          alt="Sunset"
          class="img-responsive content-bg-img"
        />
        <i class="fa fa-heart"></i>
        <h2 class="templatemo-position-relative white-text">Sunset</h2>
        <div class="view-img-btn-wrap">
          <a href="" class="btn btn-default templatemo-view-img-btn">
            View
          </a>
        </div>
      </div>
    </div>
  );
}

export default DefaultList;
