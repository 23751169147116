import React, { useContext, useState } from "react";
import Nav from "../components/nav";
import moment from "moment";
import http from "../components/http";
//import quill
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
function Post() {
  const state = useLocation().state;
  const { currentUser } = useContext(AuthContext);
  const [title, titleRef] = useState(state?.title || "");
  const myString = title;
  const slug = myString.replace(/\s+/g, "-").toLowerCase();

  const [description, descriptionRef] = useState(state?.description || "");

  const [error, setError] = useState(false);

  //React Quill
  const [body, setValue] = useState(state?.body || "");

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const module = { toolbar: toolbarOptions };

  //Date state
  let today = moment();
  let createdAt = state?.createdAt || today._d;

  //picture states
  const [file, selectedFile] = useState([]);

  var fileSelectedHandler = (event) => {
    selectedFile(event.target.files[0]);
  };
  //Upload Images
  const upload = async () => {
    try {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      const attachPics = await http.post(`upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return attachPics.data;
    } catch (error) {
      setError(true);
      console.log(error.response);
    }
  };
  //Submit Handler
  const writeHandler = async (e) => {
    e.preventDefault();
    const img = await upload();
    setError(false);

    try {
      const postItems = {
        title,
        slug,
        description,
        body,
        username: currentUser.username,
        photo: file ? img : "",
        createdAt,
      };
      /*if (file) {
        const data = new FormData();
        const filename = Date.now() + file.name;
        data.append("name", filename);
        data.append("file", file);
        postItems.photo = state?.photo || filename;

        try {
          const attachPics = await http.post(`upload`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          console.log(attachPics.data);
        } catch (error) {
          setError(true);
          console.log(error.response);
        }
      }*/
      state
        ? await http.put(`/posts/${slug}`, {
            ...state,
            title,
            slug,
            description,
            body,
            photo: file ? img : "",
            username: currentUser.username,
            createdAt,
          })
        : // const res = await axios.post("/posts", {
          await http.post(`/posts`, postItems);
      window.location.replace("/blog");
    } catch (err) {
      setError(true);
    }
  };
  return (
    <>
      <Nav />
      <div class="templatemo-content-widget white-bg col-2">
        <i class="fa fa-times"></i>
        <div class="media margin-bottom-30">
          <div class="media-left padding-right-25">
            <a href="#">
              <img
                class="media-object img-circle templatemo-img-bordered"
                src="assets/images/person.jpg"
                alt="Sunset"
              />
            </a>
          </div>
          <div class="media-body">
            <h2 class="media-heading text-uppercase blue-text">
              {currentUser.username}
            </h2>
            <p>{currentUser.email}</p>
          </div>
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>Excellent Estate Managers</p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                type="text"
                name="title"
                value={title}
                className="form-control"
                placeholder="Enter Title"
                required="true"
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>

            <div class="form-group">
              <textarea
                type="textarea"
                name="description"
                value={description}
                className="form-control"
                placeholder="Enter Meta Description"
                required="true"
                onChange={(e) => descriptionRef(e.target.value)}
              />
            </div>
            <ReactQuill
              theme="snow"
              onChange={setValue}
              value={body}
              modules={module}
              placeholder="Enter the Full Details Here"
            />

            <br />
            <div class="form-group">
              <input
                class="form-control"
                type="file"
                className="subscription-input-form"
                name="file"
                onChange={fileSelectedHandler}
              />
            </div>
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
      ;
    </>
  );
}

export default Post;
