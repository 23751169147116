import React,{useState} from 'react'
import {Chart as ChartJS} from 'chart.js/auto'
import { Bar,Line } from 'react-chartjs-2'
import useSWR from 'swr';
import { marketUrlEndpoint, paymentUrlEndpoint } from './http';
import moment from 'moment';
import { useMarkets } from '../data/market';
const fetcher = (...args) => fetch(...args).then((response) => response.json());


export default function Chart() {
    var months = ['01', '02', '03', '04', 
        '05', '06', '07', '08', 
        '09', '10', '11', '12'];
       //market
  
//property

  const { market,mkloading,mkerror } = useMarkets();
  if (mkloading) return <p>{"Market is Loading"}</p>;
    const lastmonth =(number) => {return moment().subtract(number, 'month').add(1, 'day')};
    const month = lastmonth().format('MMMM')
    //console.log(lastmonth(1).format('MMMM'))
       const defaultHandler = (market, lastmonth) => {
        return market.filter((f) => moment(f.createdAt).isSameOrBefore(lastmonth));
      };
    //  const rest = defaultHandler(market,lastmonth(1));
    //console.log(rest)
    const Data=[]
    let iterator = months.values()
    for (let elements of iterator) {
      const data= [
      
        {number:elements,month:lastmonth(elements).format('MMMM'), value:defaultHandler(market,lastmonth(elements)).length},
      ]  
      console.log(elements);
    Data.push(data)
    }
console.log(Data)


   

      if(mkerror) return <p>{'Failed to load'}</p>
  return (
    <div class="templatemo-flex-row flex-content-row templatemo-overflow-hidden"> 
    <div class="col-1 templatemo-overflow-hidden">
      <div class="templatemo-content-widget white-bg templatemo-overflow-hidden">
        <i class="fa fa-times"></i>
        <div class="templatemo-flex-row flex-content-row">
          <div class="col-1 col-lg-6 col-md-12">
            <h2 class="text-center">Modular<span class="badge">new</span></h2>
            <div id="pie_chart_div" class="templatemo-chart"></div> 
          </div>
          <div class="col-1 col-lg-6 col-md-12">
            <h2 class="text-center">Interactive<span class="badge">new</span></h2>
            <div id="bar_chart_div" class="templatemo-chart">
                
            <Bar
        data = {{
  labels: Data.map((data) => data.map((a)=> a.month)),
  datasets: [
    {
      label: 'Market Gained',
      data: Data.map((data)=> data.map((a)=>a.value)),
      borderColor: '#36A2EB',
      backgroundColor: '#9BD0F5',
    },
    {
        label: 'Users Gained',
        data: Data.map((data)=> data.map((a)=>a.value)),
        borderColor: '#36A2EB',
        backgroundColor: '#9BD0F5',
      },
  ]
}}
 
      />
                
                </div> 
          </div>  
        </div>                
      </div>
    </div>
  </div>  )
}
