import { fetcher, marketUrlEndpoint, Url } from "../components/http"
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

export function useMarkets() {
  const { data, error, isLoading } = useSWR(marketUrlEndpoint,
    fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
  );

  return {
    market: data,
    mkloading:isLoading,
    mkerror:error,
  };
}

export function useMarket(firm){
    let url = marketUrlEndpoint + firm;
console.log(url)
    const { data, error, isLoading } = useSWR(
      url,
      fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
    );
  console.log(data)
    return {
        
      market: data,
      mkloading:isLoading,
      mkerror:error,
    };
  }