import React, {useState} from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import AllUserList from "../list/allUsersList";
import NewUserList from "../list/newUserList";

function ManageUsers(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
           <button
              onClick={() => setShow(!show)}
              className="btn btn-default templatemo-view-img-btn"
            >
              {show === true ? "close" : "View New Users"}
            </button> <div class="templatemo-flex-row flex-content-row">
            
            {/* The table */}
            {show ?  <NewUserList />:<AllUserList /> }
              
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageUsers;
