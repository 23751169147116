import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import { AuthContext } from "../context/authContext";
import { mutate } from "swr";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import http from "../components/http.jsx";
import ExcelParser from "../components/excelParser.jsx";

function AddLease() {
  const post = useLocation().state;
  const { currentUser } = useContext(AuthContext);

    const [propertyName, addressRef] = useState(post?.propertyName || "");
  const [facilities, facilitiesRef] = useState(post?.facilities || "");
  const [fees, feesRef] = useState(post?.fees || "");

  const [type, apartmentRef] = useState(post?.type || "");
  const [rent, rentRef] = useState(post?.rent || "");
  const [error, setError] = useState(false);

  //useswr

  //submit handler
  const postItems = {
    propertyName,
    username: currentUser.username,
    firm: currentUser.firm,
    facilities,
    fees,
    type,
    rent
  };
  const writeHandler = async (e) => {
    e.preventDefault();
    setError(false);
    console.log(postItems)
    try {
      post
        ? await http.put(`/property/${post.id}`, {
            ...post,
            propertyName,
    username: currentUser.username,
    firm: currentUser.firm,
    facilities,
    fees,
    type,
    rent
          })
        : (await http.post(`lease`, postItems)) && addressRef("");
      mutate();

      toast.success("Property Added Successfully");
      // window.location.replace("/property");
    } catch (error) {
        console.log(error)
      setError(true);
      toast.error(error.message, {
        duration: 100,
      });
    }
  };
  //CSV

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>
              You are a MarketPlace. We make it easier for your online customers
              to buy from your shop.
            </p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="title"
                placeholder="Enter Address of Property"
                value={propertyName}
                required="true"
                onChange={(e) => addressRef(e.target.value)}
              />
            </div>
            
            <div class="form-group">
                  <input
                    class="form-control"
                    type="number"
                    name="price"
                    placeholder="Enter Annual Rent"
                    value={rent}
                    required
                    onChange={(e) => rentRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="type"
                    value={type}
                    placeholder="Describe Apartments"
                    required
                    onChange={(e) => apartmentRef(e.target.value)}
                  />
                </div>
                
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="facilities"
                    value={facilities}
                    placeholder="Facilities in the Property"
                    required="true"
                    onChange={(e) => facilitiesRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="fees"
                    placeholder="Enter fees"
                    value={fees}
                    required
                    onChange={(e) => feesRef(e.target.value)}
                  />
                </div>
                 
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddLease;
