import { fetcher , propertyUrlEndpoint} from "../components/http"
import useSWR from 'swr';
//import useSWRImmutable from 'swr/immutable';

export function useProperties() {
  const { data, error, isLoading } = useSWR(
    propertyUrlEndpoint,
    fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
  );

  return {
    properties: data,
    prloading:isLoading,
    prerror:error,
  };
}


export function useProperty(firm) {
    let url = propertyUrlEndpoint+firm;
  const { data, error, isLoading } = useSWR(
    url,
    fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
  );

  return {
    properties: data,
    prloading:isLoading,
    prerror:error,
  };
}