import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import { AuthContext } from "../context/authContext";
import { mutate } from "swr";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import http from "../components/http.jsx";
import ExcelParser from "../components/excelParser.jsx";

function AddProperty() {
  const post = useLocation().state;
  const [title, titleRef] = useState(post?.title || "");
  const { currentUser } = useContext(AuthContext);

  const myString = title;
  const slug = myString.replace(/\s+/g, "-").toLowerCase();
  //console.log(slug);
  const [address, addressRef] = useState(post?.address || "");
  const [city, cityRef] = useState(post?.city || "");
  const [state, stateRef] = useState(post?.state || "");

  const [phone, phoneRef] = useState(post?.phone || "");
  const [type, setType] = useState(post?.type || "");
  const [landLord, landmarkRef] = useState(post?.landLord || "");
  const [apartments, apartmentRef] = useState(post?.apartments || "");
  const [price, rentRef] = useState(post?.price || "");
  const [error, setError] = useState(false);

  //useswr

  //submit handler
  const postItems = {
    title,
    slug,
    address: "-",
    city: "-",
    state: "-",
    landLord: "-",
    phone: "-",
    price: "-",
    firm: currentUser.firm,
    apartments: "-",
    type: "-",
  };
  const writeHandler = async (e) => {
    e.preventDefault();
    setError(false);
    try {
      post
        ? await http.put(`/property/${post.id}`, {
            ...post,
            title,
            slug,
            address,
            city,
            state,
            landLord,
            phone,
            price,
            firm: currentUser.firm,
            apartments,
            type,
          })
        : (await http.post(`property`, postItems)) && titleRef("");
      mutate();

      toast.success("Property Added Successfully");
      // window.location.replace("/property");
    } catch (error) {
      setError(true);
      toast.error(error.message, {
        duration: 100,
      });
    }
  };
  //CSV

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>
              You are a MarketPlace. We make it easier for your online customers
              to buy from your shop.
            </p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="title"
                placeholder="Enter Name of Property"
                value={title}
                required="true"
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>
            
            {post && (
              <>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="Landlord"
                    id="landlord"
                    placeholder="Enter Landlord's Name"
                    value={landLord}
                    required
                    onChange={(e) => landmarkRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={address}
                    required="true"
                    onChange={(e) => addressRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="city"
                    value={city}
                    placeholder="City"
                    required="true"
                    onChange={(e) => cityRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="state"
                    placeholder="Enter State"
                    value={state}
                    required
                    onChange={(e) => stateRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="phone"
                    placeholder="Phone of the Landlord"
                    value={phone}
                    required
                    onChange={(e) => phoneRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="price"
                    placeholder="Enter Annual Rent"
                    value={price}
                    required
                    onChange={(e) => rentRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    name="halls"
                    value={apartments}
                    placeholder="Enter Number of Apartments"
                    required
                    onChange={(e) => apartmentRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <div class="checkbox squaredOne">
                    <input
                      type="radio"
                      value="office"
                      name="duration"
                      id={post ? type : "office"}
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label htmlFor="office">
                      <span></span>Office
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="checkbox squaredTwo">
                    <input
                      type="radio"
                      value="residence"
                      name="duration"
                      id={post ? type : "residence"}
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label htmlFor="residence">
                      <span></span>Residence
                    </label>
                  </div>
                </div>
              </>
            )}
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
        <ExcelParser />
      </div>
    </>
  );
}

export default AddProperty;
