import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";

function Details(props) {
  const { currentUser } = useContext(AuthContext);
  return (
    <div class="templatemo-flex-row flex-content-row">
      <div class="templatemo-flex-row flex-content-row">
      <div class="templatemo-content-widget white-bg col-2">
      <i class="fa fa-times"></i>
          <div class="media margin-bottom-30">
            <div class="media-left padding-right-25">
              <a href="#">
                <img
                  class="media-object img-circle templatemo-img-bordered"
                  src="assets/images/person.jpg"
                  alt="Sunset"
                />
              </a>
            </div>
            <div class="media-body">
              <h2 class="media-heading text-uppercase blue-text">
                {currentUser.username}
              </h2>
              <p>{currentUser.email}</p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <div class="circle green-bg"></div>
                  </td>
                  <td>{props.th1}</td>
                  <td>{props.total}</td>
                </tr>
                <tr>
                  <td>
                    <div class="circle pink-bg"></div>
                  </td>
                  <td>{props.th2}</td>
                  <td>{props.total2}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
          <img
            src="assets/images/sunset-big.jpg"
            alt="Sunset"
            class="img-responsive content-bg-img"
          />
          <i class="fa fa-heart"></i>
          <h2 class="templatemo-position-relative white-text">Sunset</h2>
          <div class="view-img-btn-wrap">
            <a href="" class="btn btn-default templatemo-view-img-btn">
              View
            </a>
          </div>
        </div>
        <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
          <img
            src="assets/images/sunset-big.jpg"
            alt="Sunset"
            class="img-responsive content-bg-img"
          />
          <i class="fa fa-heart"></i>
          <h2 class="templatemo-position-relative white-text">Sunset</h2>
          <div class="view-img-btn-wrap">
            <a href="" class="btn btn-default templatemo-view-img-btn">
              View
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
