import React, { useState, useContext } from "react";
import SelectField from "../components/selectField";
import Quill from "./quill";
import http from "../components/http";
import { AuthContext } from "../context/authContext";

function Input(props) {
  const [title, titleRef] = useState("");
  const { currentUser } = useContext(AuthContext);

  const myString = title;
  const slug = myString.replace(/\s+/g, "-").toLowerCase();
  //console.log(slug);
  const [price, priceRef] = useState("");
  const [capacity, capacityRef] = useState("");

  const [error, setError] = useState(false);
  const [category, setCategory] = useState(null);

  //picture states
  const [file, selectedFile] = useState([]);

  var fileSelectedHandler = (event) => {
    selectedFile(event.target.files[0]);
  };

  //get select data
  const [body, setValue] = useState(null);
  const getSelect = (category) => {
    setCategory(category);
  };
  //Get Quill
  const getBody = (body) => {
    setValue(body);
  };
  const username = currentUser.username;
  const market_id = props.id;

  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    titleRef("");
    priceRef("");
    capacityRef("");
    setValue("");

    const postItems = {
      username: currentUser.username,
      market_id: props.id,

      title,
      slug,

      price,
      capacity,

      category,
      body,
    };
    console.log(postItems);

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      postItems.profilePic = filename;
      console.log(filename);

      try {
        const attachPics = await http.post(`upload`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(attachPics.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }
    try {
      // const res = await axios.post("/posts", {
      const res = await http.post(`product`, postItems);
      window.location.replace("/category");
      console.log(res.data);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <section id="contact" class="subscription">
      <div class="container">
        <div class="row">
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}
          <div class="col-sm-12">
            <div class="subscription-input-group">
              <form
                id="contact"
                onSubmit={writeHandler}
                encType="multipart/form-data"
                method="POST"
                input
                value="csrf_token()"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        className="subscription-input-form"
                        type="name"
                        name="title"
                        placeholder="Enter Name of Event Place"
                        value={title}
                        required="true"
                        onChange={(e) => titleRef(e.target.value)}
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        className="subscription-input-form"
                        type="number"
                        name="price"
                        value={price}
                        placeholder="Price"
                        required
                        onChange={(e) => priceRef(e.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        className="subscription-input-form"
                        type="number"
                        name="capacity"
                        value={capacity}
                        placeholder="Enter Capacity"
                        autocomplete="on"
                        required
                        onChange={(e) => capacityRef(e.target.value)}
                      />
                    </fieldset>
                  </div>
                  <Quill setBody={getBody} className="col-lg-12" />
                  <SelectField classNameName="col-lg-12" onSelect={getSelect} />

                  <div className="col-lg-12">
                    <fieldset></fieldset>
                  </div>

                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="file"
                        className="subscription-input-form"
                        name="file"
                        onChange={fileSelectedHandler}
                      />
                    </fieldset>
                  </div>

                  <div classNameName="col-lg-12">
                    <fieldset>
                      <button type="submit" class="appsLand-btn subscribe-btn">
                        Add Event Centre
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Input;
