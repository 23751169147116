import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import http, { postUrlEndpoint } from "../components/http";
import { Link } from "react-router-dom";
import Sidebar from "../components/sideBar";
import Details from "../components/details";
import parse from "html-react-parser";
import toast from "react-hot-toast";
import Pagination from "../components/pagination";
import SidebarAdmin from "../components/sidebarAdmin";
import NavAdmin from "../components/navAdmin";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(postUrlEndpoint + id);
    toast.success("Post Removed Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

function Blog() {
  let num = 1;

  // Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  // console.log(records);
  useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`posts`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);
  console.log(records);
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  return (
    <div>
      <div class="templatemo-flex-row">
        <SidebarAdmin />
        <div class="templatemo-content col-1 light-gray-bg">
          <NavAdmin />
          <div class="templatemo-content-container">
            <Details
              th1={"Total Businesses"}
              th2={"Total Properties"}
              th3={"Total Tenants"}
              th4={"Vacant Properties"}
              total={posts.length}
            />

            {/* The table */}
            <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
              <p>
                We are Growing,{" "}
                <strong>
                  <Link to="/post" class="blue-text">
                    List the new Post Here
                  </Link>
                </strong>
              </p>
            </div>
            {/* The search */}
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <table class="table table-striped table-bordered templatemo-user-table">
                  <thead>
                    <tr>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          # <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Title <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          slug <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Description <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Body <span class="caret"></span>
                        </a>
                      </td>
                      <td>Edit</td>
                      <td>View</td>
                      <td>Delete</td>
                    </tr>
                  </thead>
                  {records &&
                    records.map((m, key) => {
                      return (
                        <tbody>
                          <tr key={key}>
                            <td>{num++}</td>
                            <td>{m.title}</td>
                            <td>{m.slug}</td>
                            <td>{m.description}</td>
                            <td>{parse(m.body.slice(0, 200))}...</td>
                            <td>
                              <Link
                                to={`/post/`}
                                state={m}
                                class="templatemo-edit-btn"
                              >
                                {" "}
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/blog/${m.slug}`}
                                state={m}
                                className="templatemo-link"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to=""
                                onClick={() => handleDelete(m)}
                                class="templatemo-edit-btn"
                              >
                                Delete
                              </Link>
                            </td>{" "}
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                {/* Pagination */}
                <Pagination
                  order={posts.length}
                  recordsPerPage={recordsPerPage}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
